<section class="presentation">
  <div class="presentation_message">
    <h1 class="presentation_hello">HOLA!</h1>
    <div class="presentation_text">
      Soy
      <span class="presentation_text-bold">Diego Catalán</span> estudiante de
      Ingeniería Civil Informática, desarrollador frontend y con meta de llegar
      a dirigir proyectos informáticos de alto impacto.
    </div>
    <div class="presentation_bar"></div>
  </div>
  <div class="presentation_circle">
    <img src="../../assets/images/perfil_sin_fondo.png" alt="profile-image" class="presentation_profile-picture" />
  </div>

</section>