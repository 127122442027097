<section class="navbar">
  <div class="navbar_links">
    <router-link to="/" class="navbar_link" id="home">
      <i class="fa-solid fa-house-chimney navbar_link-icon"></i>
      <div class="navbar_link-text">Home</div>
    </router-link>
    <router-link to="/proyectos" class="navbar_link">
      <i class="fa-solid fa-briefcase navbar_link-icon"></i>
      <div class="navbar_link-text">Proyectos</div>
    </router-link>
    <router-link to="/contactame" class="navbar_link">
      <i class="fa-solid fa-envelope navbar_link-icon"></i>
      <div class="navbar_link-text">Contáctame</div>
    </router-link>
    <a target="_blank" rel="noopener noreferrer" class="navbar_link" href="https://github.com/kataplan">
      <i class="fa-brands fa-github navbar_link-icon"></i>
      <div class="navbar_link-text">Github</div>
    </a>
    <a target="_blank" rel="noopener noreferrer" class="navbar_link" href="https://www.linkedin.com/in/diego-catal%C3%A1n-50ba931b5/">
      <i class="fa-brands fa-linkedin navbar_link-icon"></i>
      <div class="navbar_link-text">Linkedin</div>
    </a>
  </div>
  <div class="navbar_social-media">
    <button @click="changeScheme()">
      <div v-if="currentColor === 'light'" class="navbar_color-scheme">
        <i class="fa-solid fa-sun"></i>
      </div>
      <div v-else class="navbar_color-scheme">
        <i class="fa-solid fa-moon"></i>
      </div>
    </button>
  </div>



</section>