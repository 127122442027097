<section class="about-me">
  <div class="about-me_header">
    <h1 class="section-title">
      <span class="about-me_plus">+</span>SOBRE MÍ
      <div class="about-me_bar"></div>
    </h1>
  </div>
  <div class="about-me_content">
    <div class="about-me_circle">
      <img src="@/assets/images/rugby.jpg" alt="me-rugby" class="about-me_profile-image" />
    </div>

    <transition-group tag="ul" class="about-me_list" appear @before-enter="beforeEnter" @enter="enter">
      <li class="about-me_list_item" :data-index=1 :key=1>
        <div class="about-me_list_item_arrow">></div>
        <div class="about-me_list_item_text">
          Actualmente, soy estudiante de sexto año de
          <span class="about-me_list_item_bold">
            Ingeniería Civil Informática de la Pontificia Universidad Católica
            de Valparaíso.</span>
          En el ámbito profesional busco desarrollarme en el área de la
          ingeniería web e idealmente como full stack developer y seguidamente
          especializarme en el desarrollo de software, pues estoy seguro de que
          es un área desafiante e imprescindible para la sociedad actual.
        </div>
      </li>
      <li class="about-me_list_item" :data-index=2 :key=2>
        <div class="about-me_list_item_arrow">></div>
        <div class="about-me_list_item_text">
          Soy <span class="about-me_list_item_bold"> jugador de Rugby</span>
          desde niño, ahora estoy en la División Adulta del Sporting Rugby Club,
          participante del torneo de más alto nivel en Chile.
        </div>
      </li>
      <li class="about-me_list_item" :data-index=3 :key=3>
        <div class="about-me_list_item_arrow">></div>
        <div class="about-me_list_item_text">
          Tengo facilidades para los idiomas, además de mi idioma materno, sé
          leer y hablar
          <span class="about-me_list_item_bold">inglés e italiano</span>, lo que
          me facilita la comunicación con los demás.
        </div>
      </li>
      <li class="about-me_list_item" :data-index=4 :key=4>
        <div class="about-me_list_item_arrow">></div>
        <div class="about-me_list_item_text">
          Me motivan los proyectos serios y que tengan un alto impacto en la
          sociedad y que a la vez sean
          <span class="about-me_list_item_bold">desafiantes</span> y pongan a
          prueba mis conocimientos.
        </div>
      </li>
      <div class="about-me_download" :data-index=5 :key=5 >
        <div>Si te interesó algo te invito a</div>
        <button v-on:click="handleDownload()" class="about-me_download_btn">
          Ver mi curriculum
        </button>
      </div>
    </transition-group>
  </div>

</section>