<template>
  <div class="404">
    <h1>Error 404</h1>
  </div>
</template>

<script>
export default {
  name: "Error404",
  components: {},
};
</script>