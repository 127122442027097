<section class="proyect-details">

  <h1 class="proyect-details_title">{{proyect.title}}</h1>
  <div class="proyect-details_date">Fecha de inicio del proyecto: {{this.proyectDate}}</div>
  <p class="proyect-details_description"> {{proyect.description}}</p>
  <div class="proyect-details_slider_container">
    <ImageSlider v-if="imagesUrl.length > 0" :images="imagesUrl" />
    <div v-else class="proyect-details_spin-container">
      <div class="spin"></div>
    </div>
  </div>


  <div class="proyect-details_container">
    <div class="proyect-details_tecnologys">
      <h3 class="proyect-details_subtitle">Tecnologías</h3>
      <div class="proyect-details_stack-container">
        <img v-for="(item, index) in proyect.stack" :key="index" :src="getImgUrl(item)" :alt="item"
          class="proyect-details_icon">
      </div>
    </div>
    <div class="proyect-details_links">
      
      <a v-if="isGithubAvailabe" :href="proyect.github">
        <img v-if="isGithubAvailabe" src="@/assets/icons/github.svg" class="proyect-details_link-icon">
      </a>
      <a v-if="isOnlineAvailabe" :href="proyect.online">
        <img src="@/assets/icons/link.svg" class="proyect-details_link-icon">
      </a>
    </div>
  </div>
</section>